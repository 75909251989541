import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { IonContent } from '@ionic/react';

import { Col, Div, Row, SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import OrderModel from '$fbusiness/models/order';
import Footer from '$gcomponents/widgets/footer';
import OrderItemList from './orderItemList';

import InvoiceModel, { recalculateInvoice } from '$fbusiness/models/invoice';
import { ORDER_STATUS } from '$fbusiness/enums/options/orderStatus';
import { OrderSummary } from '$fcomponents';
import CurrentStateModel from '$fbusiness/models/currentState';
import { deriveItemPrice, derivePrice, initialPrice } from '$fbusiness/models/price';
import { recalculateItemPrice } from '$fbusiness/models/orderInvoiceItem';
import TermModel from '$fbusiness/models/term';
import NativeSelect from '$gcomponents/primitives/selectInput/nativeSelect';
import { getAccess } from '$fbusiness/helpers/util';
import WarehouseModel from '$fbusiness/models/warehouse';
import { deriveShippingDistribution } from '$components/warehouse/utils';

interface OrderShippingDetailsProps {
  order?: OrderModel;
  terms: Array<TermModel>;
  invoice?: InvoiceModel;
  onSubmitOrder: Function;
  currentState: CurrentStateModel;
  warehouses: Array<WarehouseModel>;
  onClose: () => void;
}

const OrderShippingDetails: React.FC<OrderShippingDetailsProps> = ({
  order,
  terms,
  invoice,
  warehouses,
  onClose,
  currentState,
  onSubmitOrder,
}) => {
  const [formItems, setFormItems] = useState<any>([]);
  const [pricing, setPricing] = useState<any>(initialPrice);
  const [notes, setNotes] = useState('');
  const [termId, setTermId] = useState(0);
  const ACCESS = getAccess(currentState);

  useEffect(() => {
    if (!order?.termId) return;
    setTermId(order?.termId || 0);
  }, [order?.termId]);

  const initializeFormItems = (items, isInvoice = false) => {
    if (invoice && !isInvoice) return;
    setFormItems(
      items.map((i) => {
        const status = invoice?.order?.status;
        const isFlagged = status === ORDER_STATUS.FLAGGED || status === ORDER_STATUS.CLOSED;
        const newQty = isFlagged ? 0 : i.qtyOrdered;
        const totalSent = parseInt(newQty || 0) + parseInt(i.totalSent || 0);
        const qty = (i.totalReceived || 0) + parseInt(newQty || 0);

        return {
          ...i,
          ...deriveItemPrice({ ...i, qty }),
          qty: i.qty || 0,
          qtySent: '',
          qtyOrdered: i.qtyOrdered || 0,
          ...(isInvoice && {
            qtySent: newQty || 0,
            originalSent: i.totalSent || 0,
            originalReceived: i.totalReceived,
            totalReceived: i.totalReceived || 0,
            totalSent,
          }),
          distributions: deriveShippingDistribution(
            i,
            warehouses,
            invoice?.store?.warehouseId || 0,
            newQty || 0,
          ),
        };
      }),
    );
    setPricing(derivePrice(invoice || order));
  };
  useEffect(() => {
    if (!invoice) return;
    initializeFormItems(invoice.items, true);
    setNotes('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  useEffect(() => {
    if (!order) return;
    if (formItems.length && invoice) return;
    initializeFormItems(order.items);
    setNotes('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    const newInvoice = {
      ...recalculateInvoice(
        {
          ...(invoice || order),
          items: formItems || [],
        },
        { fixedItems: formItems },
      ),
    };
    setPricing(derivePrice(newInvoice));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formItems]);

  if (!order) return null;
  const { status } = order;

  const onChangeQty = ({ newQty, newSubtotal, index, j = null, jValue = 0 }) => {
    setFormItems(
      formItems.map((item, i) => {
        if (i !== index) return item;
        if (newSubtotal !== undefined) {
          return recalculateItemPrice({ ...item }, newSubtotal);
        }
        const totalSent = parseInt(newQty || 0) + parseInt(item.originalSent || 0);
        const qty = item.totalReceived + parseInt(newQty || 0);
        const newInvoice = recalculateItemPrice({ ...item, qty });
        return {
          ...newInvoice,
          qtySent: newQty,
          totalSent,
          ...(j !== null && {
            distributions: item.distributions.map((d, k) =>
              k === j ? { ...d, quantity: Number(jValue) || '' } : d,
            ),
          }),
        };
      }),
    );
  };
  const onChangeNotes = (e) => {
    setNotes(e.target.value);
  };

  const onSubmit = () => {
    onSubmitOrder({ items: formItems, pricing, termId, noteFactory: notes });
  };

  const thereAreUntouchedItem = !!formItems.find((i) => i.qtySent === '');

  return (
    <>
      <IonContent>
        <Div className="content-wrapper">
          <OrderItemList
            ACCESS={ACCESS}
            items={formItems}
            onChangeQty={onChangeQty}
            isInvoice={!!invoice}
            isRegenerated={status === ORDER_STATUS.FLAGGED}
          />
          <Row>
            <Col gridSize={6}>
              <Div padding={SPACE.LARGE}>
                <NativeSelect
                  value={termId}
                  label="INPUT.LABEL.PAYMENT_TERM"
                  color="primary"
                  className="term-select"
                  onChange={setTermId}>
                  {[{ id: 0, name: 'None' }, ...terms].map((t, i) => (
                    <option key={i} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                </NativeSelect>
              </Div>
            </Col>
            <Col gridSize={6}>
              <OrderSummary isHidden={currentState.hidePrice} price={pricing} />
            </Col>
          </Row>

          <Div padding={SPACE.LARGE}>
            {status === ORDER_STATUS.FLAGGED && invoice?.noteStore && (
              <div className="flag-message no-print">
                <div>{intl('SCREEN.SHIPPING_MODAL.NOTE_FROM_STORE')}</div>
                <div className="error">{invoice?.noteStore}</div>
              </div>
            )}
            <textarea
              onInput={onChangeNotes}
              className="notes"
              placeholder={intl('INPUT.PLACEHOLDER.NOTES')}
            />
          </Div>
        </Div>
      </IonContent>
      <Footer>
        <Button onClick={onClose} variant="outlined">
          {intl('BUTTON.CANCEL')}
        </Button>
        <Button disabled={thereAreUntouchedItem} onClick={onSubmit}>
          {intl('SCREEN.SHIPPING_MODAL.SUBMIT_BUTTON')}
        </Button>
      </Footer>
    </>
  );
};

export default OrderShippingDetails;
