import React from 'react';
import { Table } from '$gcomponents/reusables';
import CellModel from '$gbusiness/models/cell';
import { defaultStyles } from '$gbusiness/models/table';
import { COLORS } from '$gbusiness/enums';
import OrderItemModel from '$fbusiness/models/orderDetails';
import { accessHide } from '$fbusiness/helpers/util';
import ShippingItemDistribution from './shippingItemDistribution';
// import { currency } from '$gbusiness/helpers/util';

interface OrderItemListProps {
  items: Array<OrderItemModel>;
  onChangeQty: Function;
  isInvoice: boolean;
  isRegenerated: boolean;
  ACCESS: any;
}

const TABLE = (onChangeQty, isRegenerated = false, isInvoice, ACCESS): Array<CellModel> => {
  return [
    {
      label: 'SCREEN.SHIPPING_MODAL.COLS.ITEM',
      value: 'itemName',
      component: (row) => (
        <div>
          <div>{row.itemName}</div>
          {row.modifiers?.length > 0 && (
            <div className="mod">({row.modifiers.map((i) => i.name).join(', ')})</div>
          )}
          {row.note && <div className="mod">{`"${row.note}"`}</div>}
        </div>
      ),
      className: 'main',
      width: 300,
    },
    {
      label: 'SCREEN.SHIPPING_MODAL.COLS.QTY_ORDERED',
      value: isInvoice ? 'qtyOrdered' : 'qtyOrdered',
      align: 'center',
      width: 200,
    },
    ...(isRegenerated
      ? [
          {
            label: 'SCREEN.INVOICES.COLS.QTY_STATUS_TOTAL',
            value: '',
            component: (row) => (
              <div
                className={
                  row.totalReceived !== row.totalSent ? 'error' : ''
                }>{`${row.totalReceived}/${row.totalSent}`}</div>
            ),
            align: 'center',
            width: 120,
          },
          {
            label: 'SCREEN.INVOICES.COLS.QTY_ADJUSTED',
            value: 'qty',
            align: 'center',
            width: 200,
          },
        ]
      : []),
    {
      label: 'SCREEN.SHIPPING_MODAL.COLS.QTY_PACKED',
      value: 'qty',
      component: (row, actions, index) => {
        if (!row.distributions?.length) {
          return (
            <input
              type="number"
              onFocus={(e) => e.target.select()}
              className="qty"
              value={row.qtySent || ''}
              onChange={(e) => onChangeQty({ newQty: e.target.value, index })}
            />
          );
        }
        return (
          <ShippingItemDistribution
            distributions={row.distributions}
            onChangeQty={(newQty, j, value) => onChangeQty({ newQty, index, j, jValue: value })}
          />
        );
      },
      className: 'action',
      align: 'center',
      width: 200,
    },
    {
      label: 'SCREEN.SHIPPING_MODAL.COLS.NEW_SUBTOTAL',
      value: 'subtotal',
      // component: row => currency(row.subtotal),
      component: (row, actions, index) => {
        return (
          <div className="subtotal-input">
            <input
              type="number"
              onFocus={(e) => e.target.select()}
              value={row.subtotal || ''}
              onChange={(e) => onChangeQty({ newSubtotal: e.target.value, index })}
            />
          </div>
        );
      },
      className: `${accessHide(ACCESS.MONEY.VIEW)} right`,
      minWidth: 100,
      width: 150,
    },
  ];
};

const styles = {
  ...defaultStyles,
  color: COLORS.MEDIUM,
  minWidth: 200,
  maxWidth: 800,
  headerHeight: 50,
  cellPadding: ` 8px 4px`,
  responsive: false,
  isStripped: false,
};

const OrderItemList: React.FC<OrderItemListProps> = ({
  items,
  onChangeQty,
  isInvoice,
  ACCESS,
  isRegenerated,
}) => {
  return <Table data={items} TABLE={TABLE(onChangeQty, isRegenerated, isInvoice, ACCESS)} styles={styles} />;
};

export default OrderItemList;
