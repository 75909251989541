import { COLORS } from '$gbusiness/enums';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import CellModel from '$gbusiness/models/cell';
import { currency, onPressEnter } from '$gbusiness/helpers/util';
import CounterInput from '$fcomponents/counterInput';
import { Delete } from '@mui/icons-material';
import { Flex } from '$gstyles/wrapper';

export const INVOICE_ITEM_TABLE_CONFIG = (
  onChangeBlankText,
  onEnterText,
  onDeleteItem = (row, i) => {},
): TableModel => ({
  dataSource: {
    ...defaultDataSource,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.MEDIUM,
    fontSize: '1.05em',
    minWidth: 600,
    maxWidth: 1100,
    cellPadding: ` 1px 4px`,
    responsive: true,
    isStripped: false,
    rowHeight: 44,
    headerHeight: 30,
    setRowClass: (i) => {
      return i.requireSerial ? 'serial-required' : '';
    },
  },
  blankText: (row, i) => (
    <input
      type="text"
      className="blank-field"
      data-index={i}
      value={row.itemName}
      onKeyUp={(e) => onPressEnter(e, row.itemName, onEnterText)}
      onChange={(e) => onChangeBlankText(e, i)}
    />
  ),
  blankDelete: (row, i) => <Delete onClick={() => onDeleteItem(row, i)} className="pointer" />,
});

export const SNC = 'SCREEN.INVOICES.COLS.';

export const INVOICE_ITEM_TABLE = ({
  onChangeQty,
  onDeleteItem,
  onInvalid = (e, i) => {},
  onEnter = (e) => {},
  onChangePrice,
  disableItemPriceEdit = false,
  serialEnabled = false,
  inventoryEnabled = false,
  onModifySerial = (e) => {},
}): Array<CellModel> => [
  {
    label: SNC + 'ITEMS',
    value: 'itemName',
    component: (row) => (
      <div>
        <div>{row.itemName}</div>
        {row.modifiers?.length > 0 && (
          <div className="mod">({row.modifiers.map((i) => i.name).join(', ')})</div>
        )}
        {row.note && <div className="mod">{`"${row.note}"`}</div>}
      </div>
    ),
    className: 'main',
    alwaysShow: true,
    width: 300,
  },
  // {
  //   label: SNC + 'VENDOR',
  //   value: 'vendor?.code',
  //   component: (row) => {
  //     return <>{row.vendor?.code || ''}</>;
  //   },
  //   alwaysShow: true,
  //   width: 150,
  // },
  {
    label: SNC + 'SKU',
    value: 'sku',
    alwaysShow: true,
    width: 150,
  },
  {
    label: SNC + 'SERIALS',
    value: 'serials',
    isHidden: !serialEnabled,
    component: (row, action, i) => {
      return (
        <div className="serials link" onClick={() => onModifySerial(i)}>
          {(row.serials || []).map((serial, i) => (
            <div className="serial" key={i}>
              {serial}
            </div>
          ))}
          {(!row.serials || !row.serials?.length) && (
            <div>
              <span className="add">+</span>
            </div>
          )}
        </div>
      );
    },
    width: 180,
  },
  {
    label: SNC + 'QTY',
    value: 'qtySent',
    component: (row, actions, index) => {
      if (!inventoryEnabled) {
        return (
          <CounterInput
            id={row.itemId}
            onEnter={onEnter}
            minValue={0}
            shouldSelectOnFocus
            disabled={disableItemPriceEdit && row.invoiceItemId}
            onInvalid={() => onInvalid(row.itemId, index)}
            onChange={(q, p) => onChangeQty(index, q)}
            value={row.qtySent || 0}
          />
        );
      }

      return row.invoiceDistributions.length > 0 ? (
        <div>
          {row.invoiceDistributions.map((d, i) => (
            <Flex justifyContent="flex-end" key={i}>
              <div className="inv-short">{d.short}</div>
              <CounterInput
                className="inv-qty-input"
                id={d.itemId}
                onEnter={onEnter}
                minValue={0}
                shouldSelectOnFocus
                disabled={disableItemPriceEdit && row.invoiceItemId}
                onInvalid={() => onInvalid(d.itemId, index)}
                onChange={(q, p) => onChangeQty(index, q, i)}
                value={d.quantity || 0}
              />
            </Flex>
          ))}
        </div>
      ) : (
        <div>
          <Flex justifyContent="flex-end">
            <div className="inv-short"></div>
            <CounterInput
              id={row.itemId}
              className="inv-qty-input"
              onEnter={onEnter}
              minValue={0}
              shouldSelectOnFocus
              disabled={disableItemPriceEdit && row.invoiceItemId}
              onInvalid={() => onInvalid(row.itemId, index)}
              onChange={(q, p) => onChangeQty(index, q)}
              value={row.qtySent || 0}
            />
          </Flex>
        </div>
      );
    },
    align: 'center',
    className: 'desktop',
    width: 140,
    minWidth: 120,
  },
  {
    label: SNC + 'UNIT_PRICE',
    value: 'unitPrice',
    align: 'center',
    className: 'invoice-print',
    alwaysShow: true,
    component: (row, actions, index) => (
      <div className="subtotal-input">
        <input
          type="number"
          tabIndex={-1}
          disabled={disableItemPriceEdit && row.invoiceItemId}
          onFocus={(e) => e.target.select()}
          value={row.unitPrice || ''}
          onChange={(e) => onChangePrice(index, e.target.value)}
        />
      </div>
    ),
    width: 200,
  },
  {
    label: SNC + 'SUBTOTAL',
    value: 'subtotal',
    alwaysShow: true,
    align: 'center',
    className: 'invoice-print',
    component: (row) => <>{currency(row.subtotal)}</>,
    width: 150,
    minWidth: 110,
  },
  {
    label: '',
    value: '',
    className: 'action',
    align: 'center',
    component: (row, actions, index) => (
      <div className="button-group">
        {(!disableItemPriceEdit || !row.invoiceItemId) && (
          <Delete onClick={() => onDeleteItem(row, index)} className="pointer" />
        )}
      </div>
    ),
    width: 50,
    minWidth: 50,
  },
];
