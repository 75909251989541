import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { screen } from '$fcomponents/hoc';
import { Header } from '$gcomponents/widgets';
import { userActions } from '$fbusiness/redux/user';

import { Formik } from 'formik';

import { input } from '$gbusiness/helpers';

import { initialInvoiceForm, invoiceToForm, INVOICE_FORM } from './invoiceForm';

import { IonPageWrapper } from './styles';
import { invoiceActions } from '$fbusiness/redux/invoice';
import { itemText, toNumber } from '$gbusiness/helpers/util';
import EditInvoiceDetails from './editInvoiceDetails';
import { INVOICE_NUMBER } from '$fbusiness/enums/options/invoiceNumber';
import { generateShipFrom } from './utils';
import { parseISO } from 'date-fns';
import { factoryActions } from '$fbusiness/redux/factory';
import { IconButton } from '@mui/material';
import InvoiceDetailsModal from '$fscreens/accountScreen/invoicesScreen/invoiceDetailsModal';
import { deriveRawToUser } from '$gbusiness/models/user';
import { Print } from '@mui/icons-material';
import { deriveDatetime } from '$gbusiness/helpers/date';

interface EditInvoiceScreenProps {
  onHydrate;
  onDehydrate;
  invoice: any;
  createInvoice;
  updateInvoice;
  currentState;
  factory;
  history;
  stores;
  match;
  users;
}

const EditInvoiceScreen: React.FC<EditInvoiceScreenProps> = React.memo(
  ({
    factory,
    stores: reduxStores,
    match,
    history,
    invoice: reduxInvoice,
    users,
    currentState,
    createInvoice,
    updateInvoice,
  }) => {
    const formRef = useRef<any>();
    const { isFinished } = reduxInvoice;

    const stores = [...reduxStores];
    const { params } = match;
    const { isEdit: isEditString, invoiceId, storeId } = params;
    const isEdit = isEditString === '1';
    const isInherited = !!toNumber(invoiceId);

    const invoice = isEdit
      ? reduxInvoice.invoice
      : {
          ...reduxInvoice.invoice,
          paidAmount: 0,
          balance: 0,
          refundAmount: 0,
          creditAmount: 0,
        };

    const initialValue = initialInvoiceForm();
    const { invoiceDate } = initialValue;

    const [initVal, setInitVal] = useState<any>({});
    const [showInvoice, setShowInvoice] = useState(false);
    const [reps, setReps] = useState(users);

    const { terms, shippingMethods, invoiceTypes, settings } = factory;
    const isCustomInvoice = factory.settings.invoiceStyle === INVOICE_NUMBER.CUSTOM;

    useEffect(() => {
      if (!isFinished || !invoice?.id) return;
      formRef.current.setTouched({});

      history.goBack();
      return;

      // history.goBack();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinished, invoice?.id]);

    useEffect(() => {
      if (!stores.length || !storeId) return;
      setInitVal({
        store: stores.find((s) => s.id === parseInt(storeId)),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores.length]);

    useEffect(() => {
      if (!invoice?.id) return;
      const store = stores.find((s) => s.id === invoice.store.id);
      if (!store) return;
      let discount = store.discounts.find((d) => d.amount === invoice?.discountRate && !d.isFlat);
      if (!discount && invoice.otherDiscount)
        discount = store.discounts.find((d) => d.amount === invoice.otherDiscount && d.isFlat);

      const newReps = (store?.salesmen.length ? store.salesmen : users).map(deriveRawToUser) || users;
      const user = newReps.find((s) => s.userId === invoice.userId);
      setReps(newReps.length ? newReps : users);

      const values = {
        ...invoiceToForm(invoice),
        store,
        storeNote: store?.settings?.note || '',
        user,
        discountId: discount?.id || null,
        // status: INVOICE_STATUS.OPEN,
        invoiceDate: isEdit ? parseISO(invoice.createdAt) : invoiceDate,
        shipFrom: generateShipFrom(factory),
        shippingMethodId: invoice?.shippingMethod ? invoice?.shippingMethod.id || null : null,
        noTax: invoice && !invoice?.tax ? '1' : '0',
        poNo: invoice?.order ? invoice.order.orderNumber : invoice?.detailedData?.poNo || '',
      };
      setTimeout(function () {
        setInitVal(values);
      }, 100);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoice?.id]);

    const onStoreChange = ({ value }) => {
      if (!value) {
        setInitVal({ ...initVal, store: null, user: undefined });
        return;
      }
      const newStoreId = toNumber(value.id);
      const newStore = stores.find((s) => s.id === newStoreId);
      setInitVal({
        ...initVal,
        store: newStore || null,
      });
      setReps(value?.salesmen?.length ? value?.salesmen : users);
    };

    const invoiceForm = INVOICE_FORM(
      stores,
      terms,
      initVal?.store?.discounts || [],
      invoiceTypes,
      reps,
      shippingMethods,
      onStoreChange,
      isCustomInvoice,
      settings,
    );
    const onSubmit = async (values) => {
      const param = {
        ...values,
        storeId: initVal?.store.id,
        detailedData: {
          ...(invoice?.detailedData || {}),
          poNo: values.poNo,
        },
        items: (values?.items || []).map((item) => ({
          ...item,
          distributions: [...item.invoiceDistributions],
          invoiceDistributions: undefined,
        })),
        created_at: deriveDatetime(values.invoiceDate),
        invoiceTypeId: values.invoiceTypeId || null,
        ...(values?.user && { userId: values?.user?.userId || values?.user?.id || 0 }),
        isCustomInvoice,
        id: isEdit ? invoice?.id || 0 : 0,
      };

      if (isEdit) {
        await updateInvoice(param);
        return;
      }
      await createInvoice(param, true);
    };

    const validateForm = (values) => {
      return input.validateError(invoiceForm, values);
    };

    return (
      <IonPageWrapper>
        <Header
          titleText={itemText(isEdit ? 'EDIT' : 'ADD', 'INVOICE')}
          rightButton={
            isEdit && (
              <IconButton>
                <Print onClick={() => setShowInvoice(true)} />
              </IconButton>
            )
          }
        />
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={initialValue}
          validate={validateForm}
          onSubmit={(values) => {
            onSubmit(values);
          }}>
          {(formik) => (
            <EditInvoiceDetails
              isEdit={isEdit}
              currentState={currentState}
              isInherited={isInherited}
              initVal={initVal}
              onSubmit={onSubmit}
              factory={factory}
              invoice={{
                ...invoice,
                ...(!isEdit && { items: 0 }),
              }}
              FORM={invoiceForm}
              formik={formik}
            />
          )}
        </Formik>
        <InvoiceDetailsModal
          currentState={currentState}
          invoice={invoice}
          factory={factory}
          show={!!invoice && showInvoice}
          onClose={() => setShowInvoice(false)}
        />
      </IonPageWrapper>
    );
  },
  (pp, np) =>
    pp.invoice === np.invoice &&
    pp.users.length === np.users.length &&
    pp.factory.settings.invoiceStarting === np.factory.settings.invoiceStarting &&
    pp.invoice.isFinished === np.invoice.isFinished,
);

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  invoice: state.invoice,
  users: state.user.users,
  stores: state.store.stores,
  resetOnRoute: true,
});

const mapDispatchToProps = (dispatch) => ({
  onHydrate: async ({ invoiceId }) => {
    await dispatch(factoryActions.fetchNextInvoiceNo());
    await dispatch(userActions.fetchUsers({ storeUser: true, storeId: 1 }));
    await dispatch(invoiceActions.fetchInvoice(parseInt(invoiceId || 0)));
  },
  onDehydrate: () => dispatch(invoiceActions.dehydrate()),
  createInvoice: (n, o) => dispatch(invoiceActions.createInvoice(n, o)),
  updateInvoice: (n) => dispatch(invoiceActions.updateInvoice(n)),
});

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(EditInvoiceScreen));
