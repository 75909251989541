import CellModel from '$gbusiness/models/cell';
import { COLORS, SORT } from '$gbusiness/enums';
import { defaultStyles } from '$gbusiness/models/table';
import { requiredCell, requiredNumber } from './previewTable';

export const quantityPreviewStyles = {
  ...defaultStyles,
  responsive: true,
  color: COLORS.SECONDARY,
  fontSize: '1.05em',
  minWidth: 800,
  rowHeight: 32,
  headerHeight: 50,
  maxWidth: 1200,
  cellPadding: ` 8px 4px`,
  isStripped: false,
  borderColor: 'var(--ion-border-color)',
  disableSort: true,
  setRowClass: (row) => {
    if (!validateRow(row)) return 'invalid';
    return row.id ? 'update' : 'new';
  },
};

export const validateRow = (row) => {
  const { ITEMS, quantity, id, ...rest } = row;
  if (!id || !ITEMS) return false;
  for (var i in rest) {
    if (isNaN(rest[i])) return false;
  }
  return true;
};

export const QUANTITY_PREVIEW_TABLE = (warehouses): Array<CellModel> => [
  {
    label: 'SCREEN.ITEMS.COLS.ID',
    value: 'id',
    component: (row) => row.id || 'INVALID',
    width: 120,
  },
  {
    label: 'SCREEN.ITEMS.COLS.NAME',
    value: 'ITEMS',
    className: 'main',
    component: (row) => requiredCell(row['ITEMS']),
    width: 350,
  },
  ...(warehouses || [])?.map((warehouse, i) => ({
    labelText: warehouse,
    value: warehouse,
    align: 'center',
    component: (row) => {
      return requiredNumber(row[warehouse], row[warehouse] || 0);
    },
    sortable: SORT.ASC,
    width: 60,
  })),
  {
    label: 'SCREEN.ITEMS.COLS.QUANTITY_SUM',
    value: 'quantity',
    align: 'center',
    className: 'desktop',
    component: (row) => {
      return row.quantity;
    },
    sortable: SORT.ASC,
    width: 120,
  },
];

export default QUANTITY_PREVIEW_TABLE;
