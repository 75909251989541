import { configs } from '$configs';
import { SORT, COLORS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button } from '@mui/material';
import { deriveRawToWarehouse } from '$fbusiness/models/warehouse';
import { addressNode } from '$gcomponents/utils/general';

export const WAREHOUSE_TABLE_CONFIG: TableModel = {
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.warehouse.general,
    method: 'GET',
    deriveToModel: deriveRawToWarehouse,
    defaultSortKey: 'createdAt',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1em',
    minWidth: 300,
    maxWidth: 800,
    cellPadding: ` 8px 4px`,
    isStripped: true,
    responsive: true,
  },
  filter: {
    color: COLORS.PRIMARY,
  },
};

export const WAREHOUSE_TABLE = (): Array<CellModel> => [
  {
    label: 'SCREEN.WAREHOUSES.COLS.NAME',
    value: 'name',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: 'SCREEN.WAREHOUSES.COLS.SHORT',
    value: 'short',
    align: 'center',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 120,
  },
  {
    label: 'SCREEN.WAREHOUSES.COLS.ADDRESS',
    value: 'address.formatted',
    component: (row) => <>{addressNode(row.address)}</>,
    sortable: SORT.ASC,
    width: 250,
  },
  {
    label: '',
    value: '',
    className: 'action',
    align: 'center',
    component: (row, actions) => {
      return (
        <Button
          className="left"
          color="secondary"
          fullWidth
          onClick={(e) => actions.handlePopoverOpen(e, row)}>
          <MoreHorizIcon />
        </Button>
      );
    },
    width: 100,
  },
];
