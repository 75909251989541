import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { FONT, WEIGHT } from '$gstyles';
import { disabled } from '$gstyles/mixins';

export const IonPageWrapper = styled(IonPage)`
  ion-content {
    .content-wrapper {
      max-width: 1000px;
      .MuiFormControl-root {
      }
      .item-quantity {
        font-size: ${FONT.MEDIUM};
      }
      .item-title {
        margin-top: 0;
        display: flex;
        position: relative;
        justify-content: space-between;
        .credit-section {
          font-size: ${FONT.SMALL};
          position: absolute;
          right: 0;
          top: -8px;
          text-align: right;
        }
      }
    }
    .invoice-items {
      padding: 8px 0;
    }
    .searchInput {
      /* max-width: 800px; */
    }
    .scan-barcode {
      text-align: right;
      .barcode-button {
        min-width: 120px;
        width: 90%;
      }
    }
    .ship-input {
      textarea {
        font-size: ${FONT.SMALL};
        line-height: normal;
        -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
      }
      label {
        -webkit-text-fill-color: rgba(0, 0, 0, 0.8);
      }
    }
    .main-table-wrapper {
      .MuiTableCell-root {
        min-height: initial;
      }
      .serials {
        font-size: 0.8em;
        .serial {
          line-height: 11px;
          font-family: courier;
        }
      }
      .blank-delete {
        width: 50px;
        text-align: center;
      }
      .add {
        font-size: 20px;
      }
      .serial-required {
        .counter-input {
          ${disabled}
        }
      }
      .inv-short {
        width: 30px;
      }
      .inv-qty-input {
        width: 90px;
      }
      .counter-input {
        height: initial;
        input {
          height: 24px;
        }
        svg {
          font-size: 22px;
        }
      }
      .subtotal-input {
        position: relative;
        input {
          max-width: 60px;
          text-align: center;
        }
        &:before {
          content: '$';
          left: 0;
          top: 0;
        }
      }
      .MuiTableCell-root.action {
        .MuiSvgIcon-root {
          font-size: 22px;
          margin: 0;
        }
      }
    }
    ion-backdrop,
    .MuiBackdrop-root {
      opacity: 0.8;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 11;
      display: flex;
      align-items: center;
      font-size: 60px;
      color: #fff;

      .message {
        font-size: 36px;
        height: 60px;
      }
      &.qty-mode {
        background-color: rgba(0, 0, 0, 0.5);
      }
      /*
      &.qty {
        background-color: rgba(0, 0, 0, 0.1);
        > div {
          opacity: 0 !important;
        }
      }
      */
    }
    ion-backdrop.backdrop-hide {
      z-index: -1;
    }
    .order-summary {
      display: flex;
      justify-content: flex-end;
      .line.total > * {
        font-size: 1.8rem;
        font-weight: ${WEIGHT.SEMI_BOLD};
      }
    }
  }
  ion-footer {
    .invoice-summary {
      max-width: 300px;
      padding: 8px 16px;
      li {
        padding: 2px;
      }
      .value {
        /* font-weight: ${WEIGHT.SEMI_BOLD}; */
        font-size: ${FONT.MEDIUM};
      }
      .invoice-total .value {
        font-size: ${FONT.LARGE};
        font-weight: ${WEIGHT.SEMI_BOLD};
      }
    }
    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      > * {
        text-align: center;
        margin: 4px;
      }
      &.row {
        flex-direction: row;
      }
    }
  }
`;
