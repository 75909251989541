import _ from 'lodash';
import React, { useCallback, useState } from 'react';

import { TableView2 } from '$gcomponents/widgets';

import { SERIAL_TABLE, SERIAL_TABLE_CONFIG, SERIAL_TABLE_ITEM } from './serialTable';
import FactoryModel from '$fbusiness/models/factory';
import intl from '$gintl';
import { getAccess, updatedFilter } from '$fbusiness/helpers/util';
import ItemActionModel from '$gbusiness/models/itemAction';
import { dialog } from '$gcomponents/reusables';
import { serialActions } from '$fbusiness/redux/serial';
import { itemActions as reduxItemActions } from '$fbusiness/redux/item';
import SerialModal from './serialModal';
import ItemModel from '$fbusiness/models/item';
import { useDispatch } from 'react-redux';
import BatchModal from '../batchesScreen/batchModal';
import { InputAdornment, TextField } from '@mui/material';
import { Close, DeleteOutline, Search } from '@mui/icons-material';
import { BatchSectionContainer } from './styles';
import { SERIAL_STATUS } from '$business/enums/enums';
import { batchActions } from '$fbusiness/redux/batch';
import { deriveRawToItemBatch } from '$fbusiness/models/itemBatch';
import SerialEditModal from './serialEditModal';
import CurrentStateModel from '$fbusiness/models/currentState';
import FabButton from '$fcomponents/fabButton';
const { debounce } = _;

interface SerialsSectionProps {
  factory: FactoryModel;
  currentState: CurrentStateModel;
  item?: ItemModel;
  itemId?: number;
  page?: string;
  onRefresh?: Function;
  onClickItem?: Function;
  onClickStore?: Function;
  onClickCategory?: Function;
}

const SerialsSection = React.forwardRef<SerialsSectionProps, any>(
  (
    {
      factory,
      item: originalItem,
      itemId,
      page = 'ITEM',
      onRefresh,
      currentState,
      onClickStore,
      onClickItem,
      onClickCategory,
    },
    ref,
  ) => {
    const dispatch = useDispatch();

    React.useImperativeHandle(ref, () => ({
      factory,
      item,
      itemId,
      isItemPage,
      currentState,
      onRefresh,
      onAddSerial,
      onAddBatch,
      refreshTable,
    }));

    const isItemPage = page === 'ITEM';
    const serialOptions = factory.enums[SERIAL_STATUS];
    // const isSerialPage = page === 'SERIAL';

    const [showSerialModal, setShowSerialModal] = useState(false);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const [serial, setSerial] = useState<any>(null);
    const [batch, setBatch] = useState<any>(null);
    const [item, setItem] = useState<any>(originalItem);
    const [filter, setFilter] = useState({ query: '', itemId: itemId || item?.id || 0 });
    const [query, setQuery] = useState('');
    const [selections, setSelections] = useState<any>([]);
    const ACCESS = getAccess(currentState);

    const { settings } = factory;

    const onClickBatch = async (batch, item = null) => {
      if (batch) {
        await dispatch(batchActions.fetchBatch(batch.id, true)).then(async (newBatch) => {
          await setBatch(deriveRawToItemBatch(newBatch));
          if (item) setItem(item);
          setShowBatchModal(true);
        });
      } else {
        setBatch(batch);
      }
    };

    const onChangeStatus = (serial, status) => {
      const updatedSerial = { ...serial, status };
      dispatch(serialActions.updateSerial(updatedSerial)).then(() => {
        refreshTable();
      });
    };

    const onDeleteSerial = async (id) => {
      dialog.confirm({
        message: 'MESSAGE.DELETE_WARNING',
        onPress: async () => {
          await dispatch(serialActions.deleteSerial(id));
          refreshTable();
          if (isItemPage && item?.id) dispatch(reduxItemActions.fetchItem(item?.id));
        },
      });
    };

    const onDeleteSerials = async () => {
      dialog.confirm({
        message: 'MESSAGE.DELETE_WARNING',
        onPress: async () => {
          await dispatch(serialActions.deleteSerials(selections));
          refreshTable();
          if (selections) setSelections([]);
          setFilter(updatedFilter(filter, { resetSelection: true }));
        },
      });
    };

    const onEditSerial = (serial) => {
      setSerial(serial);
    };

    const serialTable = isItemPage
      ? SERIAL_TABLE_ITEM({
          settings,
          serialOptions,
          onDeleteSerial,
          onClickBatch,
          onChangeStatus,
          onEditSerial,
          ACCESS,
        })
      : SERIAL_TABLE({
          ACCESS,
          settings,
          serialOptions,
          onDeleteSerial,
          onClickBatch,
          onChangeStatus,
          onClickItem,
          onClickStore,
          onClickCategory,
          onEditSerial,
        });

    const refreshTable = (extra = {}) => {
      setFilter(updatedFilter(filter, extra));
      if (onRefresh) onRefresh();
    };

    const onAddBatch = async (item = null) => {
      if (item) {
        setItem(item);
      }
      setShowBatchModal(true);
    };

    const onAddSerial = async (item = null) => {
      if (item) {
        setItem(item);
      }
      setShowSerialModal(true);
    };

    const onChangeQuery = (e) => {
      const value = e?.target?.value || '';
      setQuery(value);
      debouncedSearch(value);
    };

    const handleSelections = (arr) => {
      setSelections(arr.reduce((acc, a) => (a ? [...acc, a.id] : acc), []));
    };

    const updateQuery = async (val) => {
      if (val.length > 0 && val.length < 3) return;
      refreshTable({ query: val });
      // await sleep(2000);
      // const thisEl = getEl('.serial-serach-input');
      // console.log('EL', thisEl);
      // if (thisEl) {
      //   thisEl.select();
      //   thisEl.focus();
      // }
    };

    const itemActions: Array<ItemActionModel> = [
      {
        label: intl('BUTTON.DELETE'),
        eventName: 'delete',
        onClick: (row) => onDeleteSerial(row.id),
      },
    ];

    const debouncedSearch = useCallback(debounce(updateQuery, 800), []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <BatchSectionContainer>
        {settings?.serial && (
          <>
            {isItemPage && (
              <div className="search-container">
                <TextField
                  variant="outlined"
                  label={intl('INPUT.LABEL.SEARCH')}
                  size="small"
                  className="serial-serach-input"
                  onInput={onChangeQuery}
                  value={query}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Close
                          className="pointer"
                          onClick={() => {
                            setQuery('');
                            updateQuery('');
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            <TableView2
              filter={filter}
              itemActions={itemActions}
              TABLE={serialTable}
              onSelection={isItemPage ? undefined : handleSelections}
              tableConfig={SERIAL_TABLE_CONFIG(isItemPage)}></TableView2>
            <SerialModal
              factory={factory}
              item={item}
              batch={batch}
              show={showSerialModal}
              onRefresh={refreshTable}
              onClose={() => setShowSerialModal(false)}
            />
            <SerialEditModal
              serial={serial}
              show={!!serial?.id}
              onClose={() => setSerial(null)}
              onRefresh={refreshTable}
            />
          </>
        )}
        {settings?.batch && (
          <BatchModal
            currentState={factory.currentState}
            factory={factory}
            item={item}
            readOnly={batch?.id}
            show={showBatchModal}
            onRefresh={refreshTable}
            isItemPage={isItemPage}
            batch={batch}
            onClose={() => setShowBatchModal(false)}
          />
        )}
        <FabButton
          icon={<DeleteOutline />}
          color="danger"
          ver="bottom"
          className="fixed"
          isHidden={!selections.filter((s) => s).length}
          onClick={onDeleteSerials}
        />
      </BatchSectionContainer>
    );
  },
);

export default SerialsSection;
