import React, { useEffect, useState } from 'react';
import intl from '$intl';
import InputRowModel from '$gbusiness/models/inputRow';
import { IonContent } from '@ionic/react';
import { PageWrapper } from '$gstyles/wrapper';
import { Form } from 'formik';
import { FormSection, InfoItem } from '$gcomponents/reusables';
import Footer from '$gcomponents/widgets/footer';
import { ColAuto, Row } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import { initialPrice } from '$fbusiness/models/price';
import { OrderSummary } from '$fcomponents';
import InvoiceModel, { recalculateInvoice } from '$fbusiness/models/invoice';
import { deriveItemToInvoiceItem, deriveRawItemToInvoiceItem } from '$fbusiness/models/invoiceItem';
import { TableView2 } from '$gcomponents/widgets';
import { INVOICE_ITEM_TABLE, INVOICE_ITEM_TABLE_CONFIG, SNC } from './invoiceItemTable';
import ItemPicker from './itemPicker';
import { generateInvoiceNo, getAccess, getTaxRate, isAccessible } from '$fbusiness/helpers/util';
import FactoryModel from '$fbusiness/models/factory';
import {
  checkStockInvoice,
  fetchUser,
  generateShipAddress,
  generateShipFrom,
  getCommissionAmt,
  getCommissionRate,
  getDiscountRate,
  getDueDate,
} from './utils';
import { deriveRawToItem } from '$fbusiness/models/item';
import { currency, focusInputEl, percentage, sleep } from '$gbusiness/helpers/util';
import ScanBackdrop from './scanBackdrop';
import { today } from '$gbusiness/helpers/date';
import { DATE_FORMATS } from '$gbusiness/enums';
import { useDispatch } from 'react-redux';
import { cartActions } from '$fbusiness/redux/cart';
import { getEl } from '$gbusiness/helpers/input';
import CreditHistoryModal from '$fcomponents/creditHistoryModal';
import { toastDanger } from '$gbusiness/redux/toaster/actions';
import { Prompt } from 'react-router';
import SerialSelectModal from '../inventoryScreen/serialsScreen.tsx/serialSelectModal';
import CurrentStateModel from '$fbusiness/models/currentState';
import InvoicePaymentSummary from '$fcomponents/orderSummary/invoicePaymentSummary';

interface EditInvoiceDetailsProps {
  isEdit: boolean;
  isInherited: boolean;
  formik: any;
  factory: FactoryModel;
  currentState: CurrentStateModel;
  FORM: Array<InputRowModel>;
  initVal: any;
  onSubmit: Function;
  invoice?: InvoiceModel;
}

const EditInvoiceDetails: React.FC<EditInvoiceDetailsProps> = ({
  isEdit = false,
  isInherited = false,
  invoice: fetchedInvoice,
  currentState,
  formik,
  initVal,
  onSubmit,
  factory,
  FORM,
}) => {
  const dispatch = useDispatch();
  const { values } = formik;
  const { store } = initVal;
  const [invoice, setInvoice] = useState<any>({
    ...initialPrice,
    ...fetchedInvoice,
    ...values,
    items: [],
  });
  const [showScan, setShowScan] = useState(false);
  const [qtyMode, setQtyMode] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [dueDate, setDueDate] = useState(invoice?.dueDate || today());
  const [scanItem, setScanItem] = useState<any>(undefined);
  const [commission, setCommission] = useState<any>(null);
  const [deleteIds, setDeleteIds] = useState<Array<number>>([]);
  const [showCredits, setShowCredits] = useState(false);
  const [itemTouched, setItemTouched] = useState(false);
  const [serialItemIndex, setSerialItemIndex] = useState<any>(null);
  const ACCESS = getAccess(currentState);

  const {
    settings: { itemCombine, serial: serialEnabled, inventory: inventoryEnabled },
  } = factory;

  const recalculate = (inv = invoice, checkItem: any = null) => {
    const taxRate = getTaxRate(initVal.store, factory);
    const newInvoice = recalculateInvoice(
      {
        ...inv,
        taxRate,
      },
      { fixedQty: true, mutable: true, noTax: values.noTax === '1', preventCombine: !itemCombine },
    );
    if (checkItem && !checkStockInvoice(newInvoice, { ...checkItem, qtySent: checkItem.quantity })) {
      cartActions.handleOOS(dispatch, checkItem.quantity);
      return;
    }
    setInvoice({
      ...newInvoice,
      commRate: getCommissionRate(newInvoice, commission),
    });
  };

  // For editing existing invoice only
  useEffect(() => {
    if (!fetchedInvoice?.id) return;
    recalculate({
      ...fetchedInvoice,
      items: (fetchedInvoice?.items || []).map(deriveItemToInvoiceItem),
    });
    formik.setFieldValue('invoiceNumber', fetchedInvoice.invoiceNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedInvoice?.id]);

  useEffect(() => {
    if (isEdit) return;
    setTimeout(() => {
      if (!factory?.settings?.invoiceAutofill) return; //formik.setFieldValue('invoiceNumber', '');
      else {
        formik.setFieldValue(
          'invoiceNumber',
          generateInvoiceNo({ factory, orderId: '', storeId: store?.id }),
        );
      }
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [factory?.settings?.invoiceStarting]);

  // For editing existing invoice only
  useEffect(() => {
    if (!values?.termId)
      setDueDate(getDueDate({ dateFormat: DATE_FORMATS.READABLE, invoiceDate: values.invoiceDate }));
    setDueDate(
      getDueDate({
        terms: factory.terms,
        termId: values?.termId,
        dateFormat: DATE_FORMATS.READABLE,
        invoiceDate: values.invoiceDate,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.termId, values?.invoiceDate]);

  // When changing store it could change the tax rate
  useEffect(() => {
    if (!store?.id) return;
    recalculate({
      ...invoice,
      items: (invoice?.items || []).map((m) => ({
        ...deriveItemToInvoiceItem(m, store),
      })),
    });

    const newValues = {
      ...formik.values,
      ...initVal,
      shipTo: generateShipAddress(store),
      billTo: generateShipFrom(store),
      storeNote: store?.settings?.note || '',
      ...(!isInherited && {
        discountId: store?.settings?.defaultDiscount || '0',
        termId: store.termId,
      }),
      ...(!isEdit && {
        ...(factory?.settings?.invoiceAutofill
          ? { invoiceNumber: generateInvoiceNo({ factory, orderId: '', storeId: store.id }) }
          : { invoiceNumber: '' }),
      }),
    };
    setTimeout(async () => {
      formik.setValues(newValues);
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store?.id]);

  // Changing discount could impact item discount as well as flat other discount
  useEffect(() => {
    const [discountRate, otherDiscount] = getDiscountRate(factory.discounts, values.discountId);
    recalculate({
      ...invoice,
      discountRate,
      otherDiscount,
      items: (invoice?.items || []).map((m) => ({
        ...deriveItemToInvoiceItem(m),
        discountRate,
      })),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.discountId, values.noTax]);

  useEffect(() => {
    const id = values?.userId || values.user?.userId || values.user?.id || invoice?.user?.userId;
    const getCommission = async () => {
      const user = id ? await fetchUser(id) : 0;
      setCommission(values.user ? user?.other?.commission || null : null);
    };
    getCommission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.userId, values.user]);

  const onSelectItem = async (item) => {
    if (!item) return;
    const discountRate = invoice?.discountRate || 0;
    const newItem = {
      ...deriveRawItemToInvoiceItem(item, store, discountRate),
      qtySent: 1,
      ...(!item.itemId && !item.id && { qtySent: 0 }),
      id: 0,
      ...(serialEnabled &&
        item?.settings?.serial && { serials: [...(item?.serials || []), item?.settings?.serial] }),
    };
    recalculate({ ...invoice, discountRate, items: [...invoice.items, newItem] }, item);
    const index = invoice.items.length;
    if (!item.id && item.name === 'TEXT') {
      focusInputEl('.blank-field[data-index="' + index + '"]', 200);
    } else {
      if (item.settings?.requireSerial) {
        setSerialItemIndex(index);
      } else {
        focusQuantity(item.id, index);
      }
    }
    setItemTouched(true);
  };
  const focusQuantity = async (id, index, sleepTime = 200) => {
    await sleep(sleepTime);
    const selector = itemCombine
      ? '.counter-field[data-id="' + id + '"]'
      : '.MuiTableRow-root[data-index="' + index + '"] .counter-field';
    const el: any = getEl(selector);
    if (el) {
      el.select();
      el.focus();
    }
  };
  const onDeleteItem = (row, index) => {
    recalculate({ ...invoice, items: invoice.items.filter((p, i) => i !== index) });
    if (isEdit && row?.id) setDeleteIds([...deleteIds, row?.id]);
    setItemTouched(true);
  };
  const onEnter = async (val) => {
    // if (!showScan) return;
    onResetInput();
  };
  const onInvalid = async (id, index) => {
    dispatch(toastDanger({ text: 'Invalid quantity value', className: 'medium' }));
    focusQuantity(id, index);
  };
  const onResetInput = async () => {
    setQtyMode(false);
    focusOnInput();
  };
  const onChangePrice = (index, unitPrice) => {
    const item = invoice.items[index];
    if (isNaN(unitPrice)) return;
    recalculate(
      {
        ...invoice,
        items: invoice.items.map((p, i) => (i === index ? { ...p, unitPrice } : p)),
      },
      item,
    );
    setItemTouched(true);
  };

  const onChangeQty = async (index, qty, j = undefined) => {
    const item = invoice.items[index];
    const isItemText = !item.itemId && !item.unitPrice;
    const minQty = Math.max(serialEnabled ? item.serials?.length || 0 : 0);
    if (qty < minQty) return;
    if (qty < 0 && !isItemText) {
      onDeleteItem(item, index);
      onResetInput();
      return;
    }
    // if (qty <= 0 || !qty) focusQuantity(item.itemId);
    const adjustedItems = invoice.items.map((p, i) => {
      if (i !== index) return p;
      if (j === undefined) return { ...p, qtySent: qty, qty, totalSent: qty };
      // if distribution
      const adjustedDistributions = p.invoiceDistributions.map((d, k) =>
        k === j ? { ...d, quantity: qty } : d,
      );
      const newQty = adjustedDistributions.reduce((acc, r) => acc + r.quantity, 0);
      return {
        ...p,
        qtySent: newQty,
        invoiceDistributions: adjustedDistributions,
      };
    });

    recalculate(
      {
        ...invoice,
        items: adjustedItems,
      },
      item,
    );
    setItemTouched(true);
    if (showScan) {
      // onResetInput();
    }
  };
  const focusOnInput = async () => {
    const el = getEl('.react-autosuggest__input');
    el.focus();
  };
  const onScanMode = async () => {
    await onResetInput();
    setQtyMode(false);
    setShowScan(true);
    setScanItem(undefined);
  };
  const onScanFetch = async (result, barcode) => {
    setBarcode(barcode);
    if (!result?.data) {
      setScanItem(null);
    } else {
      const item = deriveRawToItem(result.data);
      setScanItem(item);
      onSelectItem(item);
      if (item.quantity !== 0) {
        setQtyMode(true);
      }
    }
    await sleep(2000);
    setScanItem(undefined);
  };
  const onViewCredits = () => {
    setShowCredits(true);
  };
  const onChangeBlankText = (e, index) => {
    const itemName = e.target.value;
    setInvoice({
      ...invoice,
      items: invoice.items.map((item, i) => (i === index ? { ...item, itemName } : item)),
    });
    setItemTouched(true);
  };
  const onModifySerial = (i) => {
    setSerialItemIndex(i);
  };
  const onChangeSerial = (serials, requireSerial) => {
    recalculate({
      ...invoice,
      items: invoice.items.map((item, i) =>
        i === serialItemIndex
          ? { ...item, serials, ...(requireSerial && { qtySent: serials.length }) }
          : item,
      ),
    });
    setSerialItemIndex(null);
  };

  const moveItem = (list, sourceIndex, destIndex) => {
    const temp = [...list];
    if (sourceIndex === destIndex) return temp;

    const currentItem = temp.splice(sourceIndex, 1)[0];
    temp.splice(destIndex, 0, currentItem);

    return temp;
  };

  const onDrag = async (result) => {
    const { source, destination } = result;
    if (source.index === destination.index) return;

    const newList = moveItem(invoice.items, source.index, destination.index);

    setInvoice({
      ...invoice,
      items: newList,
    });
  };

  const isFormTouched = Object.keys(formik.touched || {}).length > 0 || itemTouched;

  const invoiceTotal = invoice.total + (invoice?.totals?.invoiceChargeSum || 0);

  const invoicePayment = invoice
    ? {
        total: invoice.total,
        totals: invoice.totals,
        paidAmount: invoice.paidAmount,
        creditAmount: invoice.creditAmount,
        paymentDiscount: invoice.paymentDiscount,
        balance: invoice.balance,
      }
    : null;

  const canSave = isAccessible(
    isEdit ? ACCESS.ACTION.INVOICE.EDIT : ACCESS.ACTION.INVOICE.CREATE,
    currentState,
  );

  // const totalItems = invoice?.items ? invoice.items.reduce((acc, r) => acc + r.qtySent, 0) : 0;
  return (
    <>
      <IonContent>
        <Prompt when={isFormTouched} message={intl('MESSAGE.INVOICE_EXIT_WARNING')} />
        <PageWrapper className="content-wrapper">
          <Form>
            <FormSection FORM={FORM} formik={formik} marginBottom="10px" />
          </Form>
          <h3 className="item-title">
            {intl('SCREEN.ITEMS.TITLE')}
            {/* {totalItems > 1 && <span className="item-quantity"> {`(${totalItems} items)`}</span>} */}
            {store && (
              <span className="credit-section">
                <div className="credit-balance">
                  {intl('SCREEN.STORES.COLS.OPEN_BALANCE')}: {currency(store.openBalance)}
                </div>
                <div className="credit-balance">
                  {intl('SCREEN.CREDIT.COLS.BALANCE')}: {currency(store.credits)}
                </div>
                <div className="history-link link" onClick={onViewCredits}>
                  {intl('SCREEN.CREDIT.TITLE')}
                </div>
              </span>
            )}
          </h3>
          <TableView2
            className="invoice-items"
            data={invoice.items}
            onDrag={onDrag}
            TABLE={INVOICE_ITEM_TABLE({
              inventoryEnabled,
              onChangeQty,
              onDeleteItem,
              onInvalid,
              onEnter,
              onChangePrice,
              serialEnabled,
              onModifySerial,
            })}
            tableConfig={INVOICE_ITEM_TABLE_CONFIG(onChangeBlankText, onEnter, onDeleteItem)}
          />
          <ItemPicker
            disabled={!values.store}
            scanMode={showScan}
            onScanFetch={onScanFetch}
            onSelectItem={onSelectItem}
            onClickScan={onScanMode}
            enableBlank
          />
          <div className="order-summary">
            <OrderSummary price={invoice} isHidden={false} />
          </div>
          <div className="order-summary">
            <InvoicePaymentSummary
              invoicePayment={invoicePayment}
              refundCredit={invoice?.totals?.refundCreditSum}
            />
          </div>
        </PageWrapper>
        <ScanBackdrop
          item={scanItem}
          qtyMode={qtyMode}
          barcode={barcode}
          show={showScan}
          onClose={() => setShowScan(false)}
        />
      </IonContent>
      <Footer justifyContent="space-around">
        <Row width="100%">
          <ColAuto className="invoice-summary">
            <InfoItem className="due-date" label={intl(SNC + 'DUE_DATE')} value={dueDate} />
            <InfoItem
              alignCenter
              className="invoice-total"
              label={intl(SNC + 'INVOICE_TOTAL')}
              value={currency(invoiceTotal)}
            />
            {commission && (
              <InfoItem
                alignCenter
                className=""
                label={intl(SNC + 'COMM', {
                  comm: `(${percentage(invoice?.commRate || commission?.rate)})`,
                })}
                value={currency(getCommissionAmt(invoice, commission))}
              />
            )}
          </ColAuto>
          <ColAuto className="buttons">
            <Button
              className="half"
              onClick={() => {
                setItemTouched(false);
                onSubmit({
                  ...invoice,
                  ...values,
                  ...(isEdit && { deleteIds }),
                });
              }}
              disabled={!(formik.isValid && formik.dirty) || !canSave}>
              {intl('BUTTON.SAVE')}
            </Button>
          </ColAuto>
        </Row>
      </Footer>

      <CreditHistoryModal
        width="760px"
        show={showCredits}
        onClose={() => setShowCredits(false)}
        store={store}
      />
      <SerialSelectModal
        show={serialItemIndex !== null}
        item={invoice.items[serialItemIndex]}
        onSubmit={onChangeSerial}
        onClose={() => setSerialItemIndex(null)}
      />
    </>
  );
};

export default EditInvoiceDetails;
