import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import { input } from '$gbusiness/helpers';
import { addYears } from 'date-fns/esm';
import { DATE_FILTER_ITEMS } from '$fcomponents/filters/filterForm';

export const BATCH_FILTER_FORM = (items, vendors, onChangeItem, isExpiration = false) => [
  {
    items: [...DATE_FILTER_ITEMS],
  },
  ...(!isExpiration
    ? [
        {
          items: [
            {
              input: INPUT.AUTOCOMPLETE,
              label: 'SCREEN.STORES.COLS.ITEM',
              disableFastField: true,
              gridSize: 5,
              value: 'item',
              config: {
                optionProp: {
                  label: 'name',
                  labelText: (o) =>
                    `${o.sku ? `(${o.sku})` : ''} ${o.name || ''} ${o.barcode ? `(${o.barcode})` : ''}`,
                  value: 'id',
                },
                data: items,
              },
              onChange: onChangeItem,
            },
            {
              input: INPUT.SELECT,
              label: 'SCREEN.BATCH.COLS.VENDOR',
              value: 'vendorId',
              options: vendors.map((v) => ({
                labelText: v.name,
                value: v.id,
              })),
              gridSize: 3,
            },
          ],
        },
      ]
    : []),
];

export const BATCH_FORM = ({
  vendors,
  items = null,
  warehouses,
  settings,
  isEdit = false,
  disabled = false,
  requireSerial = false,
}): Array<InputRowModel> => [
  {
    items: [
      ...(items
        ? [
            {
              input: INPUT.AUTOCOMPLETE,
              label: 'SCREEN.STORES.COLS.ITEM',
              disableFastField: true,
              disabled,
              gridSize: 12,
              value: 'item',
              config: {
                optionProp: {
                  label: 'name',
                  labelText: (o) =>
                    `${o.sku ? `(${o.sku})` : ''} ${o.name || ''} ${o.barcode ? `(${o.barcode})` : ''}`,
                  value: 'id',
                },
                data: items,
              },
            },
          ]
        : [
            {
              input: INPUT.TEXT,
              label: 'SCREEN.STORES.COLS.ITEM',
              disabled: true,
              gridSize: 12,
              value: 'itemName',
            },
          ]),
      {
        label: 'SCREEN.BATCH.COLS.RECEIVENO',
        value: 'receiveNo',
        input: INPUT.TEXT,
        disabled: true,
        gridSize: 3,
        validation: [input.requiredValidation],
      },
      ...(settings?.inventory
        ? [
            {
              label: 'SCREEN.BATCH.COLS.WAREHOUSE',
              value: 'warehouseId',
              input: INPUT.SELECT,
              disabled,
              options: warehouses.map((v) => ({
                labelText: v.name,
                value: v.id,
              })),
              gridSize: 4,
            },
          ]
        : []),
      {
        label: 'SCREEN.BATCH.COLS.VENDOR',
        value: 'vendorId',
        input: INPUT.SELECT,
        disabled,
        options: vendors.map((v) => ({
          labelText: v.name,
          value: v.id,
        })),
        gridSize: settings?.inventory ? 5 : 9,
      },
      {
        label: 'SCREEN.BATCH.COLS.RECEIVE_DATE',
        value: 'receiveDate',
        input: INPUT.DATE,
        disabled,
        maxDate: addYears(new Date(), 1),
        gridSize: 4,
        validation: [input.requiredValidation],
      },
      {
        label: 'SCREEN.BATCH.COLS.SELL_BY',
        value: 'sellByDate',
        input: INPUT.DATE,
        disabled,
        maxDate: addYears(new Date(), 20),
        gridSize: 4,
      },
      {
        label: 'SCREEN.BATCH.COLS.EXPIRY',
        value: 'expirationDate',
        input: INPUT.DATE,
        disabled,
        maxDate: addYears(new Date(), 20),
        gridSize: 4,
      },
      {
        label: 'SCREEN.BATCH.COLS.LOTNO',
        value: 'lotNo',
        disabled,
        input: INPUT.TEXT,
        gridSize: 6,
      },
      {
        label: 'SCREEN.BATCH.COLS.BINNO',
        value: 'binNo',
        disabled,
        input: INPUT.TEXT,
        gridSize: 6,
      },
      {
        label: 'SCREEN.BATCH.COLS.QUANTITY',
        value: 'quantity',
        input: INPUT.NUMBER,
        disabled,
        gridSize: 6,
        validation: [input.requiredValidation],
      },
      {
        ...INPUT_TYPES.AMOUNT,
        label: 'SCREEN.BATCH.COLS.COST',
        value: 'cost',
        disabled,
        gridSize: 6,
      },
      {
        label: 'SCREEN.BATCH.COLS.MEMO',
        value: 'memo',
        input: INPUT.TEXTAREA,
        disabled,
        rows: 4,
        gridSize: 7,
      },
      ...(settings?.serial && !isEdit
        ? [
            {
              label: 'SCREEN.BATCH.COLS.SERIALS',
              value: 'serials',
              input: INPUT.TEXTAREA,
              rows: 6,
              gridSize: 5,
              ...(requireSerial && {
                validation: [
                  // input.requiredValidation,
                  {
                    condition: (v, key, values) => {
                      const lines = v.split('\n');
                      const validStr = lines.reduce((acc, line) => {
                        if (line) return acc + 1;
                        return acc;
                      }, 0);
                      return validStr !== values.quantity;
                    },
                    message: 'SERIAL_QUANTITY',
                  },
                ],
              }),
            },
          ]
        : []),
    ],
  },
];

export default BATCH_FORM;
