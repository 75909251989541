import React, { useEffect, useState } from 'react';
import intl from '$intl';
import { IonContent, IonFooter } from '@ionic/react';

import { SPACE } from '$gstyles';
import { Flex } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import DistributionItemModel from '$fbusiness/models/distributionItem';
import { TextField } from '@mui/material';
import { DistributionModalWrapper } from './styles';

interface DistributionModalProps {
  onClose: Function;
  distribution: DistributionItemModel;
  onSubmit: Function;
}

const DistributionModal: React.FC<DistributionModalProps> = ({ distribution, onClose, onSubmit }) => {
  const [quantity, setQuantity] = useState<any>(0);
  const [memo, setMemo] = useState<any>('');

  useEffect(() => {
    if (!distribution?.warehouseId) return;
    setQuantity(0);
    setMemo('');
  }, [distribution?.warehouseId]);

  const onSubmitCheck = (qty) => {
    // Prevent negative quantity. but ignore this for now
    // if (distribution.quantity + Number(qty) < 0) {
    //   dialog.alert({
    //     message: intl('MESSAGE.DISTRIBUTION_QUANTITY'),
    //   });
    //   return;
    // }
    const { warehouseId, itemId } = distribution;
    const param = {
      quantity: Number(qty),
      warehouseId,
      itemId,
      memo,
    };
    onSubmit(param);
  };

  return (
    <DistributionModalWrapper
      width="400px"
      height="320px"
      title="SCREEN.WAREHOUSES.TITLE_ADD_QUANTITY"
      titleText="Add/subtract Quantity"
      show={!!distribution}
      onClose={() => onClose()}
      useCustom>
      <IonContent>
        <div className="center">
          <Flex padding={SPACE.MEDIUM} justifyContent="center">
            Add
            <TextField
              className="qty-input"
              variant="outlined"
              type="number"
              size="small"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value)}
            />
            more items
          </Flex>
          <div>
            Memo:
            <TextField
              className="memo-input"
              rows={2}
              size="small"
              multiline
              variant="outlined"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </div>
          <div>{intl('SCREEN.WAREHOUSES.ADD_QUANTITY_CAPTION')}</div>
        </div>
      </IonContent>
      <IonFooter>
        <Flex padding={SPACE.MEDIUM} justifyContent="center">
          <Button onClick={() => onSubmitCheck(quantity)}>{intl('BUTTON.SUBMIT')}</Button>
        </Flex>
      </IonFooter>
    </DistributionModalWrapper>
  );
};

export default DistributionModal;
